<template>
  <div id="app">
    <div id="Baked" class="theme-orange">

      <nav class="navbar navbar-expand-xl py-lg-2 lp-menu">
        <div class="container py-2 rounded">

          <router-link :to="{ name: 'Index'}" class="navbar-brand pt-2 order-1" title="Baked universe staking">
            <h4 class="fw-bold px-2 yellow heroes">Baked universe staking</h4>
          </router-link>


          <div class="d-flex align-items-center order-2 order-md-2 order-xl-3">
            <template v-if="web3Modal.active">

              <div class="collapse navbar-collapse order-3 order-md-3 order-xl-2">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                  <li>
                    <a @click="disconnect" href="#" class="baked-btn baked-btn-sm">Disconnect wallet</a>
                  </li>
                  <li class="nav-item align-content-center">
                    <span class="heroes" style="font-size:10px;">{{ this.$store.getters.getAddressShort }}</span><br>
                    <span class="yellow bangers" style="font-size:20px;">{{ this.$store.getters.getBnbBalance | toBNB(5) }} BNB</span>
                  </li>
                </ul>
              </div>
            </template>
            <template v-else>

              <div class="navbar-nav order-3 order-md-3 order-xl-2" id="navbarSupportedContent">
                <a @click="connect" href="#"
                   class="baked-btn baked-btn-sm">
                  Connect
                </a>
              </div>
            </template>
          </div>


        </div>
      </nav>

      <template v-if="wrong_network">
        <main class="main">
          <div class="section mt-5">
            <div class="container text-center" style="margin-top: 100px">
              <h1 class="heroes">Please change to correct network!</h1>
            </div>
          </div>
        </main>
      </template>
      <template v-else>
        <router-view></router-view>
      </template>


      <!-- Footer -->
      <div class="footer text-center">
            <a target="_blank" href="https://twitter.com/BakedUniverse"><img
              src="~@/assets/images/staking/Twitter-logo.svg.png" class="footer-img" alt="Twitter"></a>
            <a target="_blank" href="https://www.instagram.com/bakeduniverse/"><img
              src="~@/assets/images/staking/Instagram.png" class="footer-img" alt="Instagram"></a>
            <a target="_blank" href="https://t.me/BakedPotatoesBNB"><img src="~@/assets/images/staking/Telegram.png"
                                                                         class="footer-img" alt="Telegram"></a>
            <a target="_blank" href="https://discord.gg/h57khDKd97"><img src="~@/assets/images/staking/Discord.png"
                                                                         class="footer-img" alt="Discord"></a>
            <a target="_blank" href="https://medium.com/@bakedpotatosbsc"><img
              src="~@/assets/images/staking/Medium.png" class="footer-img" alt="Medium"></a>
            <a target="_blank" href="https://m.youtube.com/channel/UCOihOB2lHg8QaT9J47G785Q"><img
              src="~@/assets/images/staking/Youtube.png" class="footer-img" alt="Youtube"></a>
      </div>

    </div>

    <web3-modal-vue
      ref="web3modal"
      :theme="theme"
      :provider-options="providerOptions"
      cache-provider
    />
    <ModalFreeMint :processing="processing" :globalError="globalError" @freeMint="freeMint($event)"></ModalFreeMint>
    <ModalFreeMintPuppets :processing="processing" :globalError="globalError" @freeMint="freeMintPuppet($event)"></ModalFreeMintPuppets>
  </div>
</template>

<style>
  @import './assets/css/custom.style.min.css';
  @import './assets/css/baked.css';

  @font-face {
    font-family: Bangers;
    src: url('./assets/fonts/Bangers/BANGERS.TTF')
  }

  @font-face {
    font-family: ComicBook;
    font-weight: 300;
    src: url('./assets/fonts/Comic Book/Comic Book.woff2')
  }

  @font-face {
    font-family: ComicBook;
    font-weight: 700;
    font-style: italic;
    src: url('./assets/fonts/Comic Book/Comic Book Bold Italic.woff2')

  }

  @font-face {
    font-family: HeroesLegend;
    src: url('./assets/fonts/Heroes Legend/HEROES LEGEND.TTF')
  }


</style>

<script>
  import jQuery from "jquery";
  import Web3ModalVue from "web3modal-vue";
  import WalletConnectProvider from "@walletconnect/web3-provider";
  import {mapState} from "vuex";
  import ModalFreeMint from "@/components/ModalFreeMint";
  import ModalFreeMintPuppets from "@/components/ModalFreeMintPuppets";
  import {getPuppetsContract, getPotatoesContract} from "@/utils/contract";

  const potatoesContract = getPotatoesContract()
  const puppetsContract = getPuppetsContract()

  window.jQuery = window.$ = jQuery

  require('./assets/bundles/bootstrap.bundle.min.js')
  require('./assets/bundles/libscripts.bundle.js')
  require('./assets/bundles/countdown.bundle.js')

  export default {
    components: {
      Web3ModalVue,
      ModalFreeMint,
      ModalFreeMintPuppets
    },
    computed: {
      ...mapState(['web3Modal'])
    },
    data() {
      return {
        processing: false,
        testing: process.env.VUE_APP_TESTING,
        theme: 'light',
        wrong_network: false,
        globalError: "",
        providerOptions: {
          walletconnect: {
            package: WalletConnectProvider,
            options: {
              rpc: {
                56: "https://bsc-dataseed.binance.org/",
                97: "https://data-seed-prebsc-1-s1.binance.org:8545",
              },
              network: process.env.VUE_APP_CHAIN_NAME
            }
          }
        },
      }
    },
    created() {
    },
    mounted() {
      let vm = this
      this.$nextTick(async () => {
        this.$nextTick(function () {
          window.setInterval(() => {
            this.checkNetwork();
          },1000);
        })

        const web3modal = this.$refs.web3modal;
        this.$store.commit('setWeb3Modal', web3modal)
        if (web3modal.cacheProvider) {
          await this.$store.dispatch('connect')

          if((
            this.$store.getters.getChainId !== process.env.VUE_APP_CHAIN_ID && this.$store.getters.getChainId.toString() !== process.env.VUE_APP_CHAIN_ID_INT
          ) && this.$store.getters.getChainId !== 0) {
            await this.$store.dispatch('switchNetwork')
          }
        }
      })
    },
    methods: {
      async checkNetwork() {
        if (this.$store.getters.currentAccount) {
          this.wrong_network = (
            this.$store.getters.getChainId !== process.env.VUE_APP_CHAIN_ID && this.$store.getters.getChainId.toString() !== process.env.VUE_APP_CHAIN_ID_INT
          ) && this.$store.getters.getChainId !== 0;
        }
      },
      freeMint () {
        this.processing = true
        this.globalError = ""

        const signer = potatoesContract.connect(this.web3Modal.signer)
        signer["mint"]()
          .then((tx) => {
            return tx.wait().then(() => {
              this.processing = false
              window.jQuery('#modal-mint').modal('hide')
            }, (error) => {
              this.globalError = error.message || error.data.message
              this.processing = false
            });
          })
          .catch((error)=>{
            this.globalError = error.message || error.data.message
            this.processing = false
          });
      },
      freeMintPuppet () {
        this.processing = true
        this.globalError = ""

        const signer = puppetsContract.connect(this.web3Modal.signer)
        signer["mint"]()
          .then((tx) => {
            return tx.wait().then(() => {
              this.processing = false
              window.jQuery('#modal-mint-puppets').modal('hide')
            }, (error) => {
              this.globalError = error.message || error.data.message
              this.processing = false
            });
          })
          .catch((error)=>{
            this.globalError = error.message || error.data.message
            this.processing = false
          });
      },
      async connect() {
        await this.$store.dispatch('connect')
      },
      async disconnect() {
        await this.$store.dispatch('resetApp')
      }
    },
  }
</script>
