<template>
  <div class="modal fade" id="modal-stake-puppets" tabindex="-1"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title fw-bold">Stake Puppets</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

          <template v-if="approved">
            <div class="modal-body">
              <div class="mb-3">
                <label class="form-label fs-6">Select tokens to stake</label>

                <div class="row g-3">

                  <div v-for="(ownedToken) in ownedTokensPuppets" v-bind:key="ownedToken.name" class="col-sm-6 col-md-6 col-lg-4 col-xl-4" v-on:click="toggleSelect(ownedToken)">
                    <div class="card-product-style border border-primary rounded p-2" :class="{ 'stake-selected': ownedToken.selected }">
                      <div class="card-product-image position-relative">
                        <img class="img-fluid rounded-3 w-100" :src="ownedToken.image_url" alt="title">
                      </div>

                      <div class="card-product-content pt-3 mt-3 border-top">
                        <h5 class="mb-2 meta-name">{{ownedToken.name}}</h5>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div class="modal-footer text-center justify-content-center">
              <small class="text_min">{{errors.staking}}</small>
              <button class="btn btn-primary text-uppercase py-2 fs-5 w-100 mt-2" id="modal-btn-transfer" type="button" @click="stake()" :disabled="selected < 1 || state.staking">
                <template v-if="!state.staking">Stake {{selected}} tokens</template>
                <template v-else>Staking...&nbsp; <i class="icofont icofont-spinner icofont-spin"></i></template>
              </button>

            </div>
          </template>

          <template v-else>
            <div class="modal-body">
              You need to approve staking contract before staking.
            </div>

            <div class="modal-footer text-center justify-content-center">
              <small class="text_min">{{errors.approving}}</small>
              <button class="btn btn-primary text-uppercase py-2 fs-5 w-100 mt-2" id="modal-btn-approve" type="button" @click="approve()" :disabled="state.approving">
                <template v-if="!state.approving">Approve</template>
                <template v-else>Approving...&nbsp; <i class="icofont icofont-spinner icofont-spin"></i></template>
              </button>
            </div>
          </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalStakePuppets',
  data () {
    return {
      selected: 0,
    }
  },
  props: {
    ownedTokensPuppets: Array,
    state: Object,
    errors: Object,
    approved: Boolean
  },
  methods: {
    stake() {
      this.$emit('stake', this.ownedTokensPuppets)
    },
    approve() {
      this.$emit('approve')
    },
    updateSelected() {
      let vm = this

      vm.selected = 0
      this.ownedTokensPuppets.forEach(function (token) {
        if (token.selected) {
          vm.selected += 1
        }
      });
    },
    toggleSelect(token) {
      token.selected = !token.selected
      this.updateSelected()
    }
  },
  watch: {
    ownedTokensPuppets: function () {
      this.updateSelected()
    }
  },
  mounted () {
    this.updateSelected()
  }
}
</script>
