<template>
  <div class="modal fade" id="modal-add-rewards" tabindex="-1"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title fw-bold">Add rewards</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

          <div class="modal-body">
            <div class="mb-3">
              <label class="form-label fs-6">Amount in BNB</label>
              <input type="text" class="form-control" v-model="reward" id="reward">
            </div>
          </div>

          <div class="modal-footer text-center justify-content-center">
            <span class="d-flex justify-content-center w-100"><strong>Please double check amount to add.</strong></span>
            <small class="text_min">{{errors.addRewards}}</small>

            <button class="btn btn-primary text-uppercase py-2 fs-5 w-100 mt-2" id="modal-btn-transfer" type="button" @click="addRewards()" :disabled="state.addRewards">
              <template v-if="!state.addRewards">Add</template>
              <template v-else>Adding...&nbsp; <i class="icofont icofont-spinner icofont-spin"></i></template>
            </button>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalAddRewards',
  data () {
    return {
      reward: 0.00,
    }
  },
  props: {
    state: Object,
    errors: Object,
    approved: Boolean
  },
  methods: {
    addRewards() {
      this.$emit('addRewards', this.reward)
    },
  }
}
</script>
