import {ethers} from 'ethers'
import Web3 from "web3"

const web3NoAccount = new Web3(new Web3.providers.HttpProvider(process.env.VUE_APP_NODE_RPC_URL))

const getWeb3NoAccount = () => {
    return web3NoAccount
}

export {getWeb3NoAccount}
export default web3NoAccount

export const simpleRpcProvider = new ethers.providers.JsonRpcProvider(
  process.env.VUE_APP_NODE_RPC_URL, {
    name: process.env.VUE_APP_CHAIN_NAME,
    chainId: parseInt(process.env.VUE_APP_CHAIN_ID_INT)
  }
)
