import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Web3 from 'web3'
import VueCountdown from '@chenfengyuan/vue-countdown';

Vue.config.productionTip = false

Vue.filter('toBNB', function (value, digits= 0) {
  if (value) {
    const priceStr = value.toString()
    const price = Web3.utils.fromWei(priceStr)
    if (!digits) {
      return price
    }
    return parseFloat(price).toFixed(digits)
  } else {
    return value
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


Vue.component(VueCountdown.name, VueCountdown);
