<template>
  <div class="modal fade" id="modal-free-mint-puppets" tabindex="-1"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title fw-bold">Mint free test Puppets</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body">
          Please mint as many tokens as you need for testing staking functionality.<br>
        </div>

        <div class="modal-footer text-center justify-content-center">
          <small class="text_min">{{globalError}}</small>
          <button class="btn btn-primary text-uppercase py-2 fs-5 w-100 mt-2" id="modal-btn-approve" type="button" @click="freeMint()" :disabled="processing">
            <template v-if="!processing">Mint</template>
            <template v-else>Minting...&nbsp; <i class="icofont icofont-spinner icofont-spin"></i></template>
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalFreeMintPuppets',
  props: {
    processing: Boolean,
    globalError: String,
  },
  methods: {
    freeMint() {
      this.$emit('freeMint')
    }
  }
}
</script>
