<template>
  <div class="modal fade" id="modal-unstake" tabindex="-1"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title fw-bold">Unstake Potatoes</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

          <template>
            <div class="modal-body">
              <div class="mb-3">
                <label class="form-label fs-6">Select tokens to unstake</label>

                <div class="row g-3">

                  <div v-for="(stakedToken) in stakedTokens" v-bind:key="stakedToken.name" class="col-sm-6 col-md-6 col-lg-4 col-xl-4" v-on:click="toggleSelect(stakedToken)">
                    <div class="card-product-style border border-primary rounded p-2" :class="{ 'stake-selected': stakedToken.selected }">
                      <div class="card-product-image position-relative">
                        <img class="img-fluid rounded-3 w-100" :src="stakedToken.image_url" alt="title">
                      </div>

                      <div class="card-product-content pt-3 mt-3 border-top">
                        <h5 class="mb-2 meta-name">{{stakedToken.name}}</h5>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div class="modal-footer text-center justify-content-center">
              <small class="text_min">{{errors.unstaking}}</small>
              <button class="btn btn-primary text-uppercase py-2 fs-5 w-100 mt-2" id="modal-btn-transfer" type="button" @click="unstake()" :disabled="selected < 1 || state.unstaking">
                <template v-if="!state.unstaking">Unstake {{selected}} tokens</template>
                <template v-else>Unstaking...&nbsp; <i class="icofont icofont-spinner icofont-spin"></i></template>
              </button>
            </div>
          </template>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalUnstake',
  data () {
    return {
      selected: 0,
    }
  },
  props: {
    stakedTokens: Array,
    state: Object,
    errors: Object,
  },
  methods: {
    unstake() {
      this.$emit('unstake', this.stakedTokens)
    },
    updateSelected() {
      let vm = this

      vm.selected = 0
      this.stakedTokens.forEach(function (token) {
        if (token.selected) {
          vm.selected += 1
        }
      });
    },
    toggleSelect(token) {
      token.selected = !token.selected
      this.updateSelected()
    }
  },
  watch: {
    stakedTokens: function () {
      this.updateSelected()
    }
  },
  mounted () {
    this.updateSelected()
  }
}
</script>
