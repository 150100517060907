<template>
  <main class="main">

    <div class="section mt-5">
      <div class="container">
        <template v-if="web3Modal.active">

          <div class="row">
            <div class="col-md-3">
              <h6 class="heroes">Total NFTs staked</h6>
              <h4 class="yellow bangers">{{totalStaked}} NFTs</h4><br>

              <h6 class="heroes">Total rewards distributed</h6>
              <h4 class="yellow bangers">{{totalRewards|toBNB(6)}} BNB</h4><br>

              <h6 class="heroes">Total rewards for <template v-if="periodFinish>0">this</template><template v-else>last</template> period</h6>
              <h4 class="yellow"><span class="yellow bangers">{{totalPeriodRewards|toBNB(2)}} BNB</span></h4><br>

              <template v-if="periodFinish>0">
                <countdown :time="periodFinish * 1000">
                  <template v-slot="props">
                    <h4>Stake period finish at</h4>
                    <h4 class="yellow bangers">
                      <span class="yellow bangers">{{ props.days }} days, {{ props.hours }} hours, {{ props.minutes }} minutes, {{ props.seconds }} seconds.</span>
                    </h4>
                  </template>
                </countdown>
              </template>
              <template v-else>
                <h6 class="heroes">Stake period finish at</h6>
                <h4 class="yellow bangers">Already finished</h4>
              </template>

              <template v-if="periodFinish>0">
                <template v-if="totalStaked>0">
                  <h4>Reward rate: {{(rewardRate/totalStaked) * 60 * 60|toBNB(4)}} BNB / hour / NFT</h4><br>
                </template>
                <template v-else>
                  <h4>Reward rate: {{(rewardRate) * 60 * 60|toBNB(4)}} BNB / hour / NFT</h4><br>
                </template>
              </template>
            </div>
            <div class="col-md-6">
              <div class="col-md-12 text-center">
                <img src="~@/assets/images/staking/Baked Universe Logo.png" width="150px" style="margin-bottom: 30px;">
                <h3 class="yellow heroes" style="margin-bottom:50px;">Baked staking platform</h3>
              </div>

              <div class="row text-center">
                <div class="col-md-6">
                  <h6 class="heroes" style="margin-bottom:20px;">BAKED POTATOES</h6>

                  <button
                    class="baked-btn baked-btn-sm"
                    data-bs-toggle="modal" data-bs-target="#modal-stake"
                  >
                    Stake
                  </button>

                  <button
                    class="baked-btn baked-btn-sm"
                    data-bs-toggle="modal" data-bs-target="#modal-unstake"
                  >
                    Unstake
                  </button>

                  <div class="row">
                    <div class="col-md-6"><h6 class="heroes">Staked</h6></div>
                    <div class="col-md-6"><h4 class="yellow bangers">{{stakedPotatoesBalance}}</h4></div>

                    <div class="col-md-6"><h6 class="heroes">Non-staked</h6></div>
                    <div class="col-md-6"><h4 class="yellow bangers">{{tokenBalance}}</h4></div>
                  </div>

                </div>

                <div class="col-md-6">
                  <h6 class="heroes" style="margin-bottom:20px;">PIFFLE PUPPETS</h6>
                  <button
                    class="baked-btn baked-btn-sm"
                    data-bs-toggle="modal" data-bs-target="#modal-stake-puppets"
                  >
                    Stake
                  </button>

                  <button
                    class="baked-btn baked-btn-sm"
                    data-bs-toggle="modal" data-bs-target="#modal-unstake-puppets"
                  >
                    Unstake
                  </button>

                  <div class="row">
                    <div class="col-md-6"><h6 class="heroes">Staked</h6></div>
                    <div class="col-md-6"><h4 class="yellow bangers">{{stakedPuppetsBalance}}</h4></div>

                    <div class="col-md-6"><h6 class="heroes">Non-staked</h6></div>
                    <div class="col-md-6"><h4 class="yellow bangers">{{tokenBalancePuppets}}</h4></div>
                  </div>
                </div>

                <div class="col-md-12 text-center">
                  <hr>
                  <img src="~@/assets/images/staking/Rounded Pointing Arrow.png" style="margin-bottom: 30px;">

                  <h6 class="heroes">Claimable rewards</h6>
                  <h4 class="yellow bangers">{{unclaimedRewards|toBNB(7)}} BNB</h4><br>

                  <h6 class="heroes" v-if="rewardRate>0 && periodFinish>0">Reward rate</h6>
                  <h4 class="yellow bangers" v-if="rewardRate>0 && periodFinish>0">{{(rewardRate/totalStaked*stakedBalance) * 60 * 60|toBNB(4)}} BNB / hour</h4><br>

                  <h6 class="heroes">Total claimed rewards</h6>
                  <h4 class="yellow bangers">{{claimedRewards|toBNB(7)}} BNB</h4><br>

                  <button
                    class="baked-btn baked-btn-sm"
                    v-on:click="claimRewards" :disabled="state.claiming" v-if="unclaimedRewards>0"
                  >
                    <template v-if="!state.claiming">Claim rewards</template>
                    <template v-else>Claiming...&nbsp; <i class="icofont icofont-spinner icofont-spin"></i></template>
                  </button>

                </div>
              </div>
            </div>
          </div>

          <ModalStake :errors="errors" :state="state" :approved="approved" :ownedTokens="ownedTokens" @stake="stake($event)" @approve="approve($event)"></ModalStake>
          <ModalStakePuppets :errors="errors" :state="state" :approved="approvedPuppets" :ownedTokensPuppets="ownedTokensPuppets" @stake="stakePuppets($event)" @approve="approvePuppets($event)"></ModalStakePuppets>

          <ModalUnstake :errors="errors" :state="state" :stakedTokens="stakedTokens" @unstake="unstake($event)"></ModalUnstake>
          <ModalUnstakePuppets :errors="errors" :state="state" :stakedTokensPuppets="stakedTokensPuppets" @unstake="unstakePuppets($event)"></ModalUnstakePuppets>

          <ModalAddRewards :errors="errors" :state="state" @addRewards="addRewards($event)"></ModalAddRewards>
        </template>

        <template v-else>
          <div class="section mt-5">
            <div class="container text-center">
              <h3 class="heroes">Please connect wallet</h3>
            </div>
          </div>
        </template>
      </div>
    </div>
  </main>
</template>

<script>
import {getStakingContract, getPotatoesContract, getStakedTokenContract, getPuppetsContract} from "@/utils/contract";
import ModalStake from "@/components/ModalStake";
import ModalStakePuppets from "@/components/ModalStakePuppets";
import ModalUnstake from "@/components/ModalUnstake";
import ModalUnstakePuppets from "@/components/ModalUnstakePuppets";
import ModalAddRewards from "@/components/ModalAddRewards";
import {mapState} from "vuex";
import axios from "axios"

const stakingContract = getStakingContract()
const potatoesContract = getPotatoesContract()
const puppetsContract = getPuppetsContract()

export default {
  name: "Home",
  components: {
    ModalStake,
    ModalStakePuppets,
    ModalUnstake,
    ModalUnstakePuppets,
    ModalAddRewards,
  },
  data () {
    return {
      testing: process.env.VUE_APP_TESTING,
      stakedPotatoesContract: null,
      stakedPuppetsContract: null,
      stakedPotatoesBalance: 0,
      stakedPuppetsBalance: 0,
      periodFinish: 0,
      rewardRate: 0,
      totalStaked: 0,
      tokenBalance: 0,
      tokenBalancePuppets: 0,
      totalPeriodRewards: 0,
      stakedBalance: 0,
      totalRewards: 0,
      unclaimedRewards: 0,
      claimedRewards: 0,
      ownedTokens: [],
      ownedTokensPuppets: [],
      stakedTokens: [],
      stakedTokensPuppets: [],
      approved: false,
      approvedPuppets: false,
      state: {
        claiming: false,
        staking: false,
        unstaking: false,
        approving: false,
        addRewards: false
      },
      errors: {
        staking: "",
        unstaking: "",
        approving: "",
        claiming: "",
        addRewards: ""
      }
    }
  },
  computed: {
    ...mapState(['web3Modal'])
  },
  methods: {
    async update() {
      window.jQuery('.modal-backdrop').remove()

      this.stakedToken_potatoes = await stakingContract['stakedToken_potatoes']()
      this.stakedToken_puppets = await stakingContract['stakedToken_puppets']()
      this.totalRewards = await stakingContract['totalRewardsPaid']()
      this.totalStaked = await stakingContract['totalSupply']()
      this.rewardRate = await stakingContract['rewardRate']()
      this.periodFinish = await stakingContract['periodFinish']() - (Date.now() / 1000)
      this.totalPeriodRewards = await stakingContract['getRewardForDuration']()

      this.stakedPotatoesContract = getStakedTokenContract(this.stakedToken_potatoes)
      this.stakedPuppetsContract = getStakedTokenContract(this.stakedToken_puppets)

      if (this.$store.getters.currentAccount) {
        let vm = this
        this.unclaimedRewards = await stakingContract['earned'](this.$store.getters.currentAccount)
        this.tokenBalance = await potatoesContract['balanceOf'](this.$store.getters.currentAccount)
        this.tokenBalancePuppets = await puppetsContract['balanceOf'](this.$store.getters.currentAccount)
        this.stakedBalance = await stakingContract['balances'](this.$store.getters.currentAccount)
        this.stakedPotatoesBalance = await this.stakedPotatoesContract['balanceOf'](this.$store.getters.currentAccount)
        this.stakedPuppetsBalance = await this.stakedPuppetsContract['balanceOf'](this.$store.getters.currentAccount)
        this.rewards = await stakingContract['rewards'](this.$store.getters.currentAccount)
        this.claimedRewards = await stakingContract['userRewardPaid'](this.$store.getters.currentAccount)
        this.approved = await potatoesContract['isApprovedForAll'](this.$store.getters.currentAccount, process.env.VUE_APP_STAKE_CONTACT_ADDRESS)
        this.approvedPuppets = await puppetsContract['isApprovedForAll'](this.$store.getters.currentAccount, process.env.VUE_APP_STAKE_CONTACT_ADDRESS)


        // Update unstaked potatoes tokens
        if (this.tokenBalance === 0) {
          this.ownedTokens = []
        } else {
          let ownedTokenIds = []
          let tokenIdPromises = []
          let newOwnedTokens = []

          for (let step = 0; step < this.tokenBalance; step++) {
            tokenIdPromises.push(potatoesContract['tokenOfOwnerByIndex'](this.$store.getters.currentAccount, step))
          }

          Promise.all(tokenIdPromises).then(function (results) {
            results.forEach(function (response) {
              ownedTokenIds.push(response.toNumber())
            });

            let tokenPromises = [];
            ownedTokenIds.forEach(function(tokenId) {
              tokenPromises.push(axios.get(process.env.VUE_APP_IPFS_GLOBAL_URL + tokenId + ".json"))
            })

            Promise.all(tokenPromises).then(function (results) {
              results.forEach(function (response) {
                response.data.image_url = response.data.image.replace("ipfs://", process.env.VUE_APP_IPFS_URL)
                response.data.selected = false
                response.data.token_id = parseInt(response.data.id)
                newOwnedTokens.push(response.data)
              });
              vm.ownedTokens = newOwnedTokens
            });
          });
        }

        // update unstaked puppets
        if (this.tokenBalancePuppets === 0) {
          this.ownedTokensPuppets = []
        } else {
          let newOwnedTokens = []

          let ownedTokenIds = await puppetsContract['tokensOfOwner'](this.$store.getters.currentAccount)

          let tokenPromises = [];
          ownedTokenIds.forEach(function(tokenId) {
            tokenPromises.push(axios.get("https://metadata.baked.kinos.one/" + tokenId + ".json"))
          })

          Promise.all(tokenPromises).then(function (results) {
            results.forEach(function (response) {
              response.data.image_url = response.data.image.replace("ipfs://", process.env.VUE_APP_IPFS_URL)
              response.data.selected = false
              response.data.token_id = parseInt(response.data.name.split("#")[1])
              newOwnedTokens.push(response.data)
            });
            vm.ownedTokensPuppets = newOwnedTokens
          });
        }

        // Update staked tokens
        if (this.stakedPotatoesBalance === 0) {
          this.stakedTokens = []
        } else {
          let stakedTokenIds = []
          let stakedIdPromises = []
          let newStakedTokens = []

          for (let step = 0; step < this.stakedPotatoesBalance; step++) {
            stakedIdPromises.push(this.stakedPotatoesContract['tokenOfOwnerByIndex'](this.$store.getters.currentAccount, step))
          }

          Promise.all(stakedIdPromises).then(function (results) {
            results.forEach(function (response) {
              stakedTokenIds.push(response.toNumber())
            });

            let stakedTokenPromises = [];
            stakedTokenIds.forEach(function(tokenId) {
              stakedTokenPromises.push(axios.get(process.env.VUE_APP_IPFS_GLOBAL_URL + tokenId + ".json"))
            })

            Promise.all(stakedTokenPromises).then(function (results) {
              results.forEach(function (response) {
                response.data.image_url = response.data.image.replace("ipfs://", process.env.VUE_APP_IPFS_URL)
                response.data.selected = false
                response.data.token_id = parseInt(response.data.name.split("#")[1])
                newStakedTokens.push(response.data)
              });
              vm.stakedTokens = newStakedTokens
            });
          });
        }

        // Puppets
        if (this.stakedPuppetsBalance === 0) {
          this.stakedTokensPuppets = []
        } else {
          let stakedTokenIds = []
          let stakedIdPromises = []
          let newStakedTokens = []

          for (let step = 0; step < this.stakedPuppetsBalance; step++) {
            stakedIdPromises.push(this.stakedPuppetsContract['tokenOfOwnerByIndex'](this.$store.getters.currentAccount, step))
          }

          Promise.all(stakedIdPromises).then(function (results) {
            results.forEach(function (response) {
              stakedTokenIds.push(response.toNumber())
            });

            let stakedTokenPromises = [];
            stakedTokenIds.forEach(function(tokenId) {
              stakedTokenPromises.push(axios.get("https://metadata.baked.kinos.one/" + tokenId + ".json"))
            })

            Promise.all(stakedTokenPromises).then(function (results) {
              results.forEach(function (response) {
                response.data.image_url = response.data.image.replace("ipfs://", process.env.VUE_APP_IPFS_URL)
                response.data.selected = false
                response.data.token_id = parseInt(response.data.name.split("#")[1])
                newStakedTokens.push(response.data)
              });
              vm.stakedTokensPuppets = newStakedTokens
            });
          });
        }

      }
    },
    stake(tokens) {
      let tokensToStake = []
      this.state.staking = true
      this.errors.staking = ""

      tokens.forEach(function (token) {
        if (token.selected) {
          tokensToStake.push(token.token_id)
        }
      });

      const signer = stakingContract.connect(this.web3Modal.signer)
      signer["stake_potatoes"](tokensToStake)
        .then((tx) => {
          return tx.wait().then(() => {
            this.state.staking = false
            window.jQuery("#modal-stake .btn-close").click()
            this.update()
          }, (error) => {
            this.state.staking = false
            try {
              this.errors.staking = error.message || error.data.message
            } catch (error) {
              this.errors.staking = "Transaction failed"
            }
          });
        })
        .catch((error) => {
          this.state.staking = false
          try {
            this.errors.staking = JSON.parse(error.message.split("from RPC ")[1].slice(1).slice(0, error.message.split("from RPC ")[1].length-2)).value.data.message
          } catch (error) {
            this.errors.staking = error.message || error.data.message
          }
        })
    },
    stakePuppets(tokens) {
      let tokensToStake = []
      this.state.staking = true
      this.errors.staking = ""

      tokens.forEach(function (token) {
        if (token.selected) {
          tokensToStake.push(token.token_id)
        }
      });

      const signer = stakingContract.connect(this.web3Modal.signer)
      signer["stake_puppets"](tokensToStake)
        .then((tx) => {
          return tx.wait().then(() => {
            this.state.staking = false
            window.jQuery("#modal-stake-puppets .btn-close").click()
            this.update()
          }, (error) => {
            this.state.staking = false
            try {
              this.errors.staking = error.message || error.data.message
            } catch (error) {
              this.errors.staking = "Transaction failed"
            }
          });
        })
        .catch((error) => {
          this.state.staking = false
          try {
            this.errors.staking = JSON.parse(error.message.split("from RPC ")[1].slice(1).slice(0, error.message.split("from RPC ")[1].length-2)).value.data.message
          } catch (error) {
            this.errors.staking = error.message || error.data.message
          }
        })
    },
    unstake(tokens) {
      let tokensToUnstake = []
      this.state.unstaking = true
      this.errors.staking = ""

      tokens.forEach(function (token) {
        if (token.selected) {
          tokensToUnstake.push(token.token_id)
        }
      });

      const signer = stakingContract.connect(this.web3Modal.signer)
      signer["withdraw_potatoes"](tokensToUnstake)
        .then((tx) => {
          return tx.wait().then(() => {
            this.state.unstaking = false
            window.jQuery("#modal-unstake .btn-close").click()
            this.update()
          }, (error) => {
            this.state.unstaking = false
            try {
              this.errors.unstaking = error.message || error.data.message
            } catch (error) {
              this.errors.unstaking = "Transaction failed"
            }
          });
        })
        .catch((error) => {
          this.state.unstaking = false
          try {
            this.errors.unstaking = JSON.parse(error.message.split("from RPC ")[1].slice(1).slice(0, error.message.split("from RPC ")[1].length-2)).value.data.message
          } catch (error) {
            this.errors.unstaking = error.message || error.data.message
          }
        })
    },
    unstakePuppets(tokens) {
      let tokensToUnstake = []
      this.state.unstaking = true
      this.errors.staking = ""

      tokens.forEach(function (token) {
        if (token.selected) {
          tokensToUnstake.push(token.token_id)
        }
      });

      const signer = stakingContract.connect(this.web3Modal.signer)
      signer["withdraw_puppets"](tokensToUnstake)
        .then((tx) => {
          return tx.wait().then(() => {
            this.state.unstaking = false
            window.jQuery("#modal-unstake-puppets .btn-close").click()
            this.update()
          }, (error) => {
            this.state.unstaking = false
            try {
              this.errors.unstaking = error.message || error.data.message
            } catch (error) {
              this.errors.unstaking = "Transaction failed"
            }
          });
        })
        .catch((error) => {
          this.state.unstaking = false
          try {
            this.errors.unstaking = JSON.parse(error.message.split("from RPC ")[1].slice(1).slice(0, error.message.split("from RPC ")[1].length-2)).value.data.message
          } catch (error) {
            this.errors.unstaking = error.message || error.data.message
          }
        })
    },
    claimRewards() {
      this.state.claiming = true;

      const signer = stakingContract.connect(this.web3Modal.signer)
      signer["getReward"]()
        .then((tx) => {
          return tx.wait().then(() => {
            this.state.claiming = false
            this.update()
          }, () => {
            this.state.claiming = false
          });
        })
        .catch((error) => {
          this.state.claiming = false
          this.errors.claiming = error.message || error.data.message
        })
    },
    approve() {
      this.state.approving = true
      this.errors.approving = ""

      const signer = potatoesContract.connect(this.web3Modal.signer)
      signer["setApprovalForAll"](process.env.VUE_APP_STAKE_CONTACT_ADDRESS, true)
        .then((tx) => {
          return tx.wait().then(() => {
            this.state.approving = false
            this.approved = true
          }, () => {
            this.state.approving = false
          });
        })
        .catch((error) => {
          this.errors.approving = error.message || error.data.message
          this.state.approving = false
        });
    },
    approvePuppets() {
      this.state.approving = true
      this.errors.approving = ""

      const signer = puppetsContract.connect(this.web3Modal.signer)
      signer["setApprovalForAll"](process.env.VUE_APP_STAKE_CONTACT_ADDRESS, true)
        .then((tx) => {
          return tx.wait().then(() => {
            this.state.approving = false
            this.approvedPuppets = true
          }, () => {
            this.state.approving = false
          });
        })
        .catch((error) => {
          this.errors.approving = error.message || error.data.message
          this.state.approving = false
        });
    },
    addRewards(reward) {
      reward = this.web3Modal.web3.utils.toWei(reward.toString())

      this.state.addRewards = true;
      this.errors.addRewards = ""

      const signer = stakingContract.connect(this.web3Modal.signer)
      signer["addRevards"]({value: reward})
        .then((tx) => {
          return tx.wait().then(() => {
            this.state.addRewards = false
          }, (error) => {
            this.state.addRewards = false
            console.log(error)
          });
        })
        .catch((error) => {
          this.state.addRewards = false
          this.errors.addRewards = error.message || error.data.message
          console.log(error)
        });
    },
    async updateRewards() {
      this.totalRewards = await stakingContract['totalRewardsPaid']()
      this.totalStaked = await stakingContract['totalSupply']()

      if (this.$store.getters.currentAccount) {
        this.unclaimedRewards = await stakingContract['earned'](this.$store.getters.currentAccount)
        this.claimedRewards = await stakingContract['userRewardPaid'](this.$store.getters.currentAccount)
        this.tokenBalance = await potatoesContract['balanceOf'](this.$store.getters.currentAccount)
        this.stakedBalance = await stakingContract['balances'](this.$store.getters.currentAccount)
        this.approved = await potatoesContract['isApprovedForAll'](this.$store.getters.currentAccount, process.env.VUE_APP_STAKE_CONTACT_ADDRESS)
        this.approvedPuppets = await puppetsContract['isApprovedForAll'](this.$store.getters.currentAccount, process.env.VUE_APP_STAKE_CONTACT_ADDRESS)
      }
    }
  },
  watch: {
    '$store.state.web3Modal.account': async function () {
      await this.update()
    }
  },
  async mounted () {
    await this.update()
    this.$nextTick(function () {
      window.setInterval(() => {
        this.updateRewards();
      },5000);
    })
  },
}
</script>
